/**
 * Custom Component - Amenities & Facilities
 * @author Charles Harwood
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.amenities {
  font-size: 2em;
}

.amenities .amenities-title {
  font-family: "Frank Ruhl Libre", serif;
  border-bottom: 0;
  color: #000000;
  font-size: 1.4em;
}

.amenities .amenities-description {
  font-size: 0.8em;
}

.amenities .amenity {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 3.75em;
  font-size: 0.5em;
}

.amenities .amenity .amenity-image {
  flex-basis: 6rem;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 6rem;
}

.amenities .amenity .amenity-image img {
  vertical-align: middle;
}

.amenities .amenity .amenity-name {
  flex-basis: 10rem;
  flex-grow: 1;
  flex-shrink: 0;
  padding-left: 1.25em;
  line-height: 1.375em;
  font-size: 1.6em;
}

@media (min-width: 1200px) and (max-width: 1469px) {
  .amenities .amenity {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media (max-width: 991px) {
  .amenities .amenity {
    margin-top: 1.25em;
  }
}

@media (max-width: 374px) {
  .amenities .amenity {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media (min-width: 1630px) {
  .amenities {
    max-width: 66rem !important;
    margin-left: auto;
  }
  .amenities .amenities-description {
    margin-bottom: 1.875em;
  }
}

.amenities.wide {
  text-align: center;
  padding: 0 1.5rem;
  max-width: 1617px !important;
  margin: 0em auto 7.1em;
}

.amenities.wide .amenities-description {
  max-width: 770px;
  margin: 0 auto;
  margin-bottom: 0.2em;
}

.amenities.wide .amenities-title {
  margin-bottom: 0.9em;
}

.amenities.wide .amenities-title:after {
  display: none;
}

@media (max-width: 1199px) {
  .amenities.wide .row {
    margin: 0;
  }
}

.amenities.wide .row .amenity {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  display: inline-block;
  margin-top: 3.3em;
}

.amenities.wide .row .amenity-name {
  padding-left: 0;
  text-align: center;
  margin-top: 0.3em;
}

.amenities.wide .row .amenity-image {
  margin: 0 auto;
}

@media (max-width: 1023px) {
  .amenities.wide .row .amenity {
    flex-basis: 50%;
    max-width: 50%;
    display: inline-flex;
    padding: 0;
  }
  .amenities.wide .row .amenity-name {
    text-align: left;
    padding-left: 1em;
    margin-top: 0;
  }
}

@media (max-width: 991px) {
  .amenities.wide .row .amenity {
    margin-top: 1.25em;
  }
}

@media (max-width: 767px) {
  .amenities.wide .row .amenity {
    flex-basis: 100%;
    max-width: 100%;
  }
}
